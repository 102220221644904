<template>
    <div class="slide-container">
        
        <div class="content">
            <div id="code-editor" class="code-editor" v-show="curEnv == 'editor'"></div>

            <div class="slide-area-wrapper" @contextmenu.prevent>
                <div class="slide-area"
                    @contextmenu.prevent
                    @click.stop.prevent="clickLeftBtn" 
                    @click.right.stop.prevent="clickRightBtn"
                >
                    <div class="slide-show-area reveal-viewport">
                        <div class="reveal">
                            <div class="slides" id="slides">
                                <!-- <section id="empty-slide"></section> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
/* eslint-disable no-undef */

import ace from "ace-builds";
import "ace-builds/webpack-resolver"; // 在 webpack 环境中使用必须要导入
import "ace-builds/src-noconflict/theme-monokai"; // 默认设置的主题
import "ace-builds/src-noconflict/mode-markdown"; // 默认设置的语言模式

import $ from 'jquery';
import cos from "@/utils/cos";
import { mapGetters } from 'vuex'

import { getCourseInfo, getSectionInfo } from '@/utils/common'
import { genResId } from '@/api/web'
import { md2html, getAdminSectionData, saveAdminSectionRes } from '@/api/admin'

export default {
    name: "slide-md",
    data: function () {
        return {
            curEnv: '',

            resId: '',
            content: "",
            editor: null,

            // 编辑器中的当前行数
            curRow: 0,
            // 当前slide位置
            curSlidePos: { h: 0, v: 0, f: 0 },

            // 定时器
            intervalAutoUpdate: null,
        };
    },
    computed: {
        ...mapGetters([
            'pid',
            'cid',
            'sid',
        ]),
    },
    created() {
    },
    mounted() {
        this.initAce()

        // 加载userInfo
        this.loadUserInfo();
    },
    activated() {
        this.initSlide()
    },
    deactivated() {
        clearInterval(this.intervalAutoUpdate);
        Reveal.removeEventListeners();
    },
    methods: {
        getCourseInfo,
        getSectionInfo,
        async loadUserInfo() {
            await this.$store.dispatch('user/getUserInfo');
        },
        loadPage(cid, sid, pid, curEnv = null) {
            if (curEnv) {
                this.curEnv = curEnv
            }

            this.content = ""

            if (this.curEnv == 'classroom') {
                this.loadClassroomData(cid, sid)

            } else if (this.curEnv == 'editor') {
                this.loadEditorData(cid, sid)
            }
        },
        async loadClassroomData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)
            
            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data) {
                this.resId = res.data.resId
            
                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载上课信息
            this.getCourseInfo(cid)

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadEditorData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)

            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data) {
                this.resId = res.data.resId
            
                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadData() {
            if (this.resId) {
                let data = await cos.getObject('project', 'project', this.resId + '.md');
                if (data && data.Body) {
                    this.editor.setValue(JSON.parse(data.Body), -1);
                }
                this.loadSlide()
            }
            // 启动定时保存
            this.intervalAutoUpdate = setInterval(this.loadSlide, 1500);
        },
        async loadSlide() {
            let newContent = this.editor.getValue().trim()

            // 如果内容没有变化（或者都为空），则不用重新加载
            if (newContent != this.content) {
                
                // 将md转换成html
                let res = await md2html(newContent)
                this.loadXML(res.data)

                // if (newContent.charAt(0) === '<') {
                //     this.loadXML(newContent)
                // } else {
                //     this.loadMarkdown(newContent)
                // }

                // 保存上次变化后的内容
                this.content = newContent
            }

            // 加载完成后，给“向前”，“向后”按钮加上事件终止（revealjs中没有。。）
            // 避免影响父层点击翻页事件
            $('.navigate-left').on("click", function(e) {
                e.stopPropagation()
            })
            $('.navigate-right').on("click", function(e) {
                e.stopPropagation()
            })
        },
        loadXML(content) {
            if (Reveal && Reveal.loadXML) {
                Reveal.loadXML(content)

                let highlightPlugin = Reveal.getPlugin('highlight')
                if (highlightPlugin) {
                    highlightPlugin.init(Reveal)
                }
                // 跳转到当前slide
                if (this.curSlidePos) {
                    Reveal.slide(this.curSlidePos.h, this.curSlidePos.v, this.curSlidePos.f)
                } else {
                    Reveal.slide(0)
                }
            }
        },
        loadMarkdown(content) {
            if (Reveal && Reveal.loadMarkdown) {
                Reveal.loadMarkdown(content)

                let markdownPlugin = Reveal.getPlugin('markdown')
                if (markdownPlugin) {
                    markdownPlugin.init(Reveal)
                }

                // 加载完Markdown后，必须要跳转一下页面，才能显示出来，可能是bug，或者是有地方需要refresh
                Reveal.slide(0)
            }
        },

        initSlide() {
            Reveal.initialize({
                // Display presentation control arrows
                controls: true,
                // Help the user learn the controls by providing hints, for example by
                // bouncing the down arrow when they first encounter a vertical slide
                controlsTutorial: false,
                // Display a presentation progress bar
                progress: true,

                // width: 800,
                margin: 0,
                center: true,
                embedded: true,
                // disableLayout: true,

                mouseWheel: false,
                help: false,
                transition: 'slide',
                // hash: true,

                keyboard: true,
                // 键盘控制播放，和鼠标点击有点冲突，后面再研究
                // keyboard: {
                //     13: 'next',
                //     32: () => {
                //         var currentSlide = Reveal.getCurrentSlide();
                //         var currentVideo = currentSlide.getElementsByTagName("video")[0];
                //         if (currentVideo) {
                //             if (currentVideo.paused == true) currentVideo.play();
                //             else currentVideo.pause();
                //         }
                //         else {
                //             Reveal.next();
                //         }
                //     }
                // },

                plugins: [ RevealMarkdown, RevealHighlight ]
            });
            // document.querySelector( '.reveal' ).style.width = '100%';
            // Reveal.layout()

            this.initRevealListener()

            this.addVideoPlayListener()
        },
        // 点击左键，翻到下一页
        clickLeftBtn(e) {
            this.removeVideoPlayListener()

            if (Reveal && Reveal.next) {
                Reveal.next()

                this.addVideoPlayListener()
            }
        },
        // 点击右键，翻到上一页
        clickRightBtn(e) {
            if (Reveal && Reveal.prev) {
                Reveal.prev()
            }
        },
        initRevealListener() {
            let that = this

            if ( Reveal ) {
                Reveal.addEventListener( "ready", function ( /* event */ ) {
                    if ( !Reveal.clear ) {
                        Reveal.clear = function () {
                            this.dom = {};
                            this.dom.slides = document.querySelector( ".reveal .slides" );

                            // Deleting all sections first
                            while (this.dom.slides.firstChild) {
                                this.dom.slides.removeChild(this.dom.slides.firstChild)
                            }
                            this.dom.slides.innerHTML = ''

                            Reveal.sync();
                        };
                    }
                    if ( !Reveal.loadXML ) {
                        Reveal.loadXML = function ( content ) {
                            this.dom = {};
                            this.dom.slides = document.querySelector( ".reveal .slides" );

                            // Deleting all sections first
                            while (this.dom.slides.firstChild) {
                                this.dom.slides.removeChild(this.dom.slides.firstChild)
                            }
                            this.dom.slides.innerHTML = content

                            Reveal.sync();
                        };
                    }
                    if ( !Reveal.loadMarkdown ) {
                        Reveal.loadMarkdown = function ( content ) {
                            let section = '<section class="future" data-markdown data-separator="^\n---\n$" data-separator-vertical="^\n\n--\n\n$">' +
                            '<script type="text/template">' +
                            content +
                            // eslint-disable-next-line
                            '<\/script></section>'

                            let newSlide = document.createElement( "section" );

                            this.dom = {};

                            this.dom.slides = document.querySelector( ".reveal .slides" );

                            // Deleting all sections first
                            while (this.dom.slides.firstChild) {
                                this.dom.slides.removeChild(this.dom.slides.firstChild)
                            }  

                            //	Adding slide to end
                            newSlide.classList.add( "future" );
                            this.dom.slides.appendChild( newSlide );

                            newSlide.outerHTML = section;

                            Reveal.sync();
                        };
                    }
                    if ( !Reveal.addMarkdown ) {
                        Reveal.addMarkdown = function ( content ) {
                            let section = '<section class="future" data-markdown data-separator="^\n---\n$" data-separator-vertical="^\n\n--\n\n$">' +
                            '<script type="text/template">' +
                            content +
                            // eslint-disable-next-line
                            '<\/script></section>'

                            var newSlide = document.createElement( "section" );

                            this.dom = {};

                            this.dom.slides = document.querySelector( ".reveal .slides" );

                            //	Adding slide to end
                            newSlide.classList.add( "future" );
                            this.dom.slides.appendChild( newSlide );

                            newSlide.outerHTML = section;

                            Reveal.sync();
                        };
                    }
                    if ( !Reveal.add ) {
                        Reveal.add = function ( content, index, id ) {
                            var newSlide = document.createElement( "section" );
                            if ( id ) {
                                newSlide.setAttribute( "id", id );
                            }

                            content = content || "";
                            index = ( index !== undefined && index !== null ) ? index : -1;

                            this.dom = {};
                            this.dom.slides = document.querySelector( ".reveal .slides" );

                            if ( index === -1 ) {
                                //	Adding slide to end
                                newSlide.classList.add( "future" );
                                this.dom.slides.appendChild( newSlide );

                                //	Just enable it, even if it already is
                                document.querySelector( ".navigate-right" ).classList.add( "enabled" );
                            }
                            else {
                                if ( index > Reveal.getIndices().h ) {
                                    newSlide.classList.add( "future" );
                                    this.dom.slides.insertBefore( newSlide, this.dom.slides.querySelectorAll( "section:nth-child(" + ( index + 1 ) + ")" )[ 0 ] );
                                }
                                else {
                                    if ( index <= Reveal.getIndices().h ) {
                                        newSlide.classList.add( "past" );
                                        this.dom.slides.insertBefore( newSlide, this.dom.slides.querySelectorAll( "section:nth-child(" + ( index + 1 ) + ")" )[ 0 ] );
                                        Reveal.next();
                                    }
                                }
                            }
                            if ( typeof content === "object" && content instanceof HTMLElement ) {
                                newSlide.appendChild( content );
                            }
                            else {
                                newSlide.innerHTML = content;
                            }
                            Reveal.sync();
                        };
                    }
                    if ( !Reveal.remove ) {
                        Reveal.remove = function ( index, id ) {
                            var targetSelector, targetElement, target;

                            this.dom = {};
                            this.dom.wrapper = document.querySelector( ".reveal" );
                            this.dom.slides = document.querySelector( ".reveal > .slides" );

                            index = ( index !== undefined && index !== null ) ? index : -1;
                            // console.log(index)

                            if ( id !== null && id !== undefined ) {
                                targetSelector = ".slides > #" + id;
                            }
                            else {
                                targetSelector = ".slides > section:nth-child(" + ( index + 1 ) + ")";
                            }
                            // console.log(targetSelector)

                            targetElement = this.dom.wrapper.querySelectorAll( targetSelector )[ 0 ];
                            // console.log(targetElement)
                            target = targetElement ? targetElement : false;

                            if ( index === -1 ) {
                                if ( Reveal.isLastSlide() ) {
                                    Reveal.prev();
                                }
                                this.dom.slides.removeChild( this.dom.wrapper.querySelectorAll( ".slides > section" )[ this.dom.wrapper.querySelectorAll( ".slides > section" ).length - 1 ] );
                                if ( Reveal.isLastSlide() ) {
                                    document.querySelector( ".navigate-right" ).classList.remove( "enabled" );
                                }
                            }
                            else {
                                if ( index > Reveal.getIndices().h && target ) {
                                    this.dom.slides.removeChild( target );
                                    if ( Reveal.getIndices().h === this.dom.wrapper.querySelectorAll( ".slides > section" ).length - 1 ) {
                                        document.querySelector( ".navigate-right" ).classList.remove( "enabled" );
                                    }
                                }
                                else {
                                    if ( index < Reveal.getIndices().h && target ) {
                                        this.dom.slides.removeChild( target );
                                        Reveal.slide( Reveal.getIndices().h - 1 );
                                    }
                                    else {
                                        if ( index === Reveal.getIndices().h && target ) {
                                            if ( index === 0 ) {
                                                Reveal.next();
                                                document.querySelector( ".navigate-left" ).classList.remove( "enabled" );
                                            }
                                            else {
                                                Reveal.prev();
                                            }
                                            this.dom.slides.removeChild( target );
                                            if ( this.dom.wrapper.querySelectorAll( ".slides > section" ).length === index ) {
                                                document.querySelector( ".navigate-right" ).classList.remove( "enabled" );
                                            }
                                        }
                                    }
                                }
                            }
                            Reveal.sync();
                        };
                    }
                });
                // 切换Slide时
                Reveal.addEventListener( "slidechanged", function ( /* event */ ) {
                    if (Reveal) {
                        that.curSlidePos = Reveal.getIndices()
                    }
                });
                // 更新单张Slide中的动画时（frame）
                Reveal.addEventListener( "fragmentshown", function ( /* event */ ) {
                    if (Reveal) {
                        that.curSlidePos = Reveal.getIndices()
                    }
                });
            }
        },
        toggleVideo(event) {
            if (event.target) {
                let currentVideo = event.target
                if (currentVideo.paused == true) currentVideo.play();
                else currentVideo.pause();
            }
            event.preventDefault();
            event.stopPropagation();
        },
        addVideoPlayListener() {
            var videoElem = document.getElementById("video")
            if (videoElem) {
                videoElem.addEventListener('click', this.toggleVideo, false)
            }
        },
        removeVideoPlayListener() {
            var videoElem = document.getElementById("video")
            if (videoElem) {
                videoElem.removeEventListener('click', this.toggleVideo, false)
            }
        },

        initAce() {
            let that = this

            this.editor = ace.edit("code-editor");
            this.editor.setTheme("ace/theme/monokai");
            // this.editor.setTheme("ace/theme/twilight");
            // this.editor.setTheme("ace/theme/tomorrow_night_eighties");
            this.editor.session.setMode("ace/mode/markdown");
            // ethis.editorditor.setReadOnly(true);
            this.editor.session.setUseWrapMode(true);
            this.editor.setOptions({
                selectionStyle: "text",
                hScrollBarAlwaysVisible: false,
                vScrollBarAlwaysVisible: false,
                fontSize: "18px",
                showLineNumbers: true,
                showFoldWidgets: true,
                highlightActiveLine: false,
                displayIndentGuides: false,
                showInvisibles: false,
                showPrintMargin: false,
                printMargin: false,
                printMarginColumn: false,
                fixedWidthGutter: false,
                wrap: true,

                // spellcheck: true,
                // enableBasicAutocompletion: true,
                // enableLiveAutocompletion: true,
                // enableSnippets: true,
            });
            this.editor.getSession().selection.on('changeCursor', function() {
                that.editorChangeCursor()
            })

        },
        editorChangeCursor() {
            let curCursorPos = this.editor.getCursorPosition()
            if (this.curRow != curCursorPos.row) {
                this.curRow = curCursorPos.row

                // 取开头到当前光标位置的内容
                let range = new ace.Range(0, 0, curCursorPos.row, curCursorPos.column);
                let content = this.editor.getSession().getTextRange(range)

                let arrContent = content.split(/\n---\n/)
                if (arrContent && arrContent.length && arrContent.length > 0) {
                    let slideIndex = arrContent.length - 1
                    if (slideIndex != this.curSlidePos.h) {
                        // 更新当前slide位置
                        this.curSlidePos = { h: slideIndex, v: 0, f: -1 }
                        Reveal.slide(slideIndex)
                    }
                }
            }
        },
        getEditorContent() {
            return this.editor.getValue()
        },
        // clear() {
        //     // 清空编辑区
        //     if (this.editor) {
        //         this.editor.setValue("");
        //     }
        //     // if (Reveal && Reveal.clear) {
        //     //     Reveal.clear()
        //     // }
        //     this.loadSlide()
        // },
        handleCmd(data) {
            switch (data.cmd) {
                case 'save':
                    this.save()
                    break
                case 'release':
                    this.release()
                    break
                case 'sync':
                    this.sync()
                    break
            }
        },
        // 保存项目数据
        async save() {
            // 只在editor下可以保存
            if (this.curEnv == 'editor') {
                if (this.resId == '') {
                    let res = await genResId()
                    this.resId = res.data.resId
                }

                // 获取Markdown数据
                let mkData = this.getEditorContent()
                await cos.putObject('project', 'project', this.resId + '.md', JSON.stringify(mkData));
                // 将md转换成html
                let res = await md2html(mkData)
                // 保存html数据
                await cos.putObject('project', 'project', this.resId + '.html', JSON.stringify(res.data));

                // 更新数据库
                saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '保存成功'});
            }
        },
        release() {

        },
        sync() {
            
        }
    },
    components: {
    },
};
</script>


<style lang="scss">
/**
 * 调整revealjs默认样式
 */
.reveal h1,
.reveal h2,
.reveal h3,
.reveal h4,
.reveal h5,
.reveal h6 {
    margin: 0 0 35px 0;
    font-family: Source Sans Pro, Helvetica, sans-serif;
    font-weight: 600;
}
.reveal h1 {
    font-size: 2.0em;
    text-shadow: none;
}
.reveal h2 {
    font-size: 1.6em;
}
.reveal h3 {
    font-size: 1.3em;
}
// 列表字体大小和行距
.reveal section p {
    font-size: 0.8em;
    line-height: 1.6em;
}
.reveal section ol li {
    font-size: 0.8em;
    line-height: 1.9em;
}
.reveal section ul li {
    font-size: 0.8em;
    line-height: 1.9em;
}
.reveal section strong {
    color: orange;
}
.reveal code {
    font-size: 130%;
    line-height: 1.3em;
}
// .reveal pre {
//     width: 100%;
// }

.reveal table {
    font-size: 0.8em;
}
.reveal blockquote {
    font-size: 0.8em;
    text-align: left;
    width: 100%;
    padding: 5px 15px;
}

// 支持双栏
.reveal .slides #left {
    margin: 10px 0 15px 20px;
    text-align: left;
    float: left;
    z-index:-10;
    width:48%;
    font-size: 0.85em;
    line-height: 1.5; 
}
.reveal .slides #right {
    margin: 10px 0 15px 0;
    float: right;
    text-align: left;
    z-index:-10;
    width:48%;
    font-size: 0.85em;
    line-height: 1.5;
}

// 视频指针变成小手
.reveal .slides #video {
    cursor: pointer;
}
</style>

<style lang="scss" scoped>
.slide-container {
    width: 100%;
    height: 100%;
    display: flex;
    // flex-direction: column;
}
.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;

    .code-editor {
        flex: 1;
        height: 100%;
        border-right: 1px solid #222;
    }
    .slide-area-wrapper {
        flex: 1;
        width: 80%;

        border-left: 1px solid #444;
        background-color: #222;
        display: flex;
        justify-content: center;
        align-items: center;

        .slide-area {
            /*宽高比70%*/
            height: 0;
            width: 100%;
            // padding-bottom: 56.25%; // 16:9
            padding-bottom: 62.5%; // 16:10
            max-height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            // background: black;
            position: relative;

            .slide-show-area {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // background-color: red;
            }
        }
    }
}
</style>


<style lang="scss">
/******************************
 * 代码编辑器相关
 ******************************/

/*代码编辑器相关*/
.code-area .code-editor {
    line-height: 25px;
}

/*优化编辑器样式配色*/
.ace-tomorrow-night-eighties {
    background-color: #272822 !important;
    color: #cccccc;
}
.ace-tomorrow-night-eighties .ace_gutter {
    background: #2f3129 !important;
    color: #8f908a !important;
}
.ace-tomorrow-night-eighties .ace_print-margin {
    width: 1px;
    background: #2f3129 !important;
}
.ace-tomorrow-night-eighties.ace_multiselect .ace_selection.ace_start {
    box-shadow: 0 0 3px 0px #272822 !important;
}

// .ace-monokai {
//   background-color:
// }
/*优化编辑器样式配色*/
.ace-monokai {
    background-color: #1f1e2e !important;
    // color: #cccccc;
}
.ace-monokai .ace_gutter {
    background: #1f1e2e !important;
    color: #8f908a !important;
}
.ace-monokai .ace_gutter-active-line {
    background-color: #ffc107;
}
.ace-monokai .ace_print-margin {
    width: 1px;
    background: #2f3129 !important;
}
.ace-monokai.ace_multiselect .ace_selection.ace_start {
    box-shadow: 0 0 3px 0px #272822 !important;
}

.ace_scrollbar-v {
    overflow-y: hidden !important;
}
</style>
